<template>
  <div class="staff_result">
    <div class="staff_result_img">
      <img src="../assets/img/staffOk.png">
    </div>

    <ul>
      <li>
        <span>产品名称</span>
        <span>{{ productName }}</span>
      </li>
      <li>
        <span>投资人姓名</span>
        <span>{{ investUserName }}</span>
      </li>
      <li>
        <span>预约金额（万元）</span>
        <span>{{ reserveAmount }}</span>
      </li>
    </ul>
    <div class="staff_result_btn" v-if="isBtn">
      <a href="javascript:;" @click="linkFun(1)">返回查看产品</a>
      <a href="javascript:;" @click="linkFun(2)" v-if="isShowSign">去签约</a>
      <a href="javascript:;" @click="linkFun(3)" v-if="isShow">查看预约记录</a>
    </div>

  </div>
</template>
<script>
import { checkUserIsFinancialTest } from "../services/proApi";
import basic from "../utils/basic";

export default {
  data() {
    return {
      productName: '',
      investUserName: '',
      reserveAmount: '',
      isBtn:true,
      isShowSign:false,
      isShow:false,
    };
  },
  created: function() {
    var _this = this;
     _this.productName = _this.$route.query.productName;
    _this.investUserName = _this.$route.query.investUserName;
    _this.reserveAmount = _this.$route.query.reserveAmount;
    _this.reserveId = _this.$route.query.reserveId;
    _this.sign = _this.$route.query.sign;
    let signType=_this.$route.query.signType;//签约类型 0：线上，1：线下】
    if(signType==0){
      _this.isShowSign=true;
      _this.isShow=false;
    }else{
      _this.isShow=true;
      _this.isShowSign=false;
    }

  },
  methods: {
    linkFun(linkName) {
      var _this = this;
      var channelFlag = _this.$route.query.channelFlag;//渠道
      if (linkName == 1) {
         _this.$router.push({
            path: "productList", query: {
              // productName: productName,
            }
          });
      } else {
        _this.$router.push({
          path: "signList", query: {}
        });
      }
    },

  }
};
</script>
<style lang="scss" scoped>
.staff_result {
  font-size: 0.8rem;
   .ProviderDialog {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    display: -webkit-box;
    -webkit-box-orient: horizontal;
    -webkit-box-pack: center;
    -webkit-box-align: center;
    background: rgba(0, 0, 0, .3);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .ProviderBox {
    position: relative;
    background: #fff;
    width: 80%;
    border-radius:0.25rem;
    text-align: left;
    padding: 1rem;
    box-sizing: border-box;

    .close {
      width: 1.4rem;
      height: 1.4rem;
      position: absolute;
      right: 0rem;
      top: -2rem;
      background-image: url(../assets/img/signPopClose.png);
      background-size: 100% 100%;
      background-repeat: no-repeat;

      &:after {
        content: '';
        display: block;
        width: 0.2rem;
        height: 1rem;
        background-color: #fff;
        position: absolute;
        left: 50%;
        bottom: -1rem;
        transform: translateX(-50%);
      }
    }
    .adviser{padding-top:1rem;}

    h3 {
      font-size: 0.9rem;
    }
  }
  .adviserinp{
    margin-top: 0.5rem;
}
  .staff_result_img {
      margin: 0 auto;
      text-align: center;
      padding: 3rem 0;
      img{width: 40%;}
  }
  ul{
        background: #fff;
    li {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #dfdfdf;
        padding: 1rem 0;
        margin: 0 1rem;
        span {
            text-align: left;
        }
        span:last-child {
            text-align: right;
            color: #999;
        }

    }
  }
  .staff_result_btn {
      margin-top: 2rem;
      padding-bottom: 2rem;
      text-align: center;
      position: fixed;
      width: 100%;
      bottom: 0;
      a {
          border: 1px solid #0062FF;
          color: #0062FF;
          border-radius: 25px;
          height: 2.4rem;
          line-height: 2.4rem;
          text-align: center;
          display: inline-block;
          width: 40%;
      }
     a:last-child {
          background: -webkit-linear-gradient(left,#0398FF,#356BFF);
          color: #fff;
          margin-left: 5%;
      }
  }
  .tips {
    font-size: .6rem;
    width: 100%;
    text-align: center;
    padding: .6rem 0;
  }
}

</style>
